import { createAction, props } from '@ngrx/store';

export const fetchUserInfo = createAction('[Account/API] Fetch User Info');
export const fetchUserInfoFault = createAction(
  '[Account/API] Fetch User Info Fault',
  props<{ message: string }>(),
);
export const fetchUserInfoSuccess = createAction(
  '[Account/API] Fetch User Info Success',
  props<{
    fullName: string;
    guid: string;
    id: number;
    userName: string;
    role: { id: number; name: string; shortForm: string };
  }>(),
);
